import React from 'react';

const Support = () => {
  return (
    <section className="bg-gray-600/25 body-font">
      <div className="container items-center px-5 py-24 mx-auto flex flex-wrap">
        <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
          <div className="bg-amber-500 relative">
            <div className="p-6 text-center">
              <h2 className="text-white  text-4xl  title-font font-medium">
                Support Us
              </h2>
              <p className="leading-relaxed text-base text-white">
                Your support helps us to reach more people and make a difference
                in the world.
              </p>
              <div className="grid grid-cols-6 gap-4 items-center p-4">
                <img src="payments/mpesa.png" alt="paypal" className="w-full" />
                <img src="payments/paypal.png" alt="mpesa" className="w-full" />
                <img src="payments/visa.png" alt="visa" className="w-full" />
                <img
                  src="payments/applepay.png"
                  alt="mpesa"
                  className="w-full"
                />
                <img
                  src="payments/mastercard.png"
                  alt="visa"
                  className="w-full"
                />
                <img
                  src="payments/discover.png"
                  alt="visa"
                  className="w-full"
                />
              </div>
              <input
                className="w-full bg-white rounded-sm focus:outline-none focus:shadow-outline text-base border-2 border-amber-950 px-4 py-2 mt-4"
                placeholder="Enter Amount"
              />
              <div className="flex justify-evenly my-4">
                <p className="p-2 border border-white bg-amber-800 text-gray-100">
                  $ 50{' '}
                </p>
                <p className="p-2 border border-white bg-amber-800 text-gray-100">
                  $ 150{' '}
                </p>
                <p className="p-2 border border-white bg-amber-800 text-gray-100">
                  $ 500{' '}
                </p>
                <p className="p-2 border border-white bg-amber-800 text-gray-100">
                  Custom{' '}
                </p>
              </div>
              <div className="flex justify-center">
                <button className="inline-flex text-white bg-red-800 border-0 py-2 px-24 focus:outline-none hover:bg-gray-100 hover:text-red-800 rounded-xl">
                  GIVE NOW
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <span className="inline-block py-1 px-2 rounded  text-gray-500 text-xs font-medium tracking-widest">
            THANK YOU FOR GIVING
          </span>
          <h2 className="sm:text-3xl text-2xl title-font font-bold text-gray-900 mb-4">
            CATEGORY GIFTS
          </h2>
          <div className="flex justify-center   items-center">
            <div className="w-24 h-12 inline-flex items-center justify-center   bg-gray-600 mb-5"></div>
            <div className="flex-grow mx-4">
              <h2 className="text-gray-600 text-lg title-font font-medium mb-3">
                Platinum Gift Card
              </h2>
            </div>
          </div>
          <div className="flex justify-center   items-center">
            <div className="w-24 h-12 inline-flex items-center justify-center   bg-amber-500 mb-5"></div>
            <div className="flex-grow mx-4">
              <h2 className="text-amber-500 text-lg title-font font-medium mb-3">
                Gold Card
              </h2>
            </div>
          </div>
          <div className="flex justify-center   items-center">
            <div className="w-24 h-12 inline-flex items-center justify-center   bg-gray-400 mb-5"></div>
            <div className="flex-grow mx-4">
              <h2 className="text-gray-400 text-lg title-font font-medium mb-3">
                Silver Card
              </h2>
            </div>
          </div>
          <div className="flex justify-center   items-center">
            <div className="w-24 h-12 inline-flex items-center justify-center   bg-lime-900 mb-5"></div>
            <div className="flex-grow mx-4">
              <h2 className="text-lime-900 text-lg title-font font-medium mb-3">
                Bronze Card
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
