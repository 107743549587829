import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RoutedData from './pages';

function App() {
  return (
    <div className="bg-white">
      <Routes>
        <Route path="/*" element={<RoutedData />} />
      </Routes>
    </div>
  );
}

export default App;
