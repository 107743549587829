import React from 'react';
import Carousel from '../components/Carousel';

export default function Hero() {
  return (
    <div className="h-[80vdh] relative">
      <div
        className="md:w-full z-10 h-full grid place-content-center  justify-start p-2 md:pl-12"
        style={{ position: 'absolute' }}
      >
        <h1 className="text-3xl lg:text-7xl font-brush  text-gray-100">
          Touch Lives
        </h1>
        <h1 className="text-3xl lg:text-7xl font-brush tracking-widest  text-gray-100">
          Change the World
        </h1>
        <div>
          <img src="payments/yellow.png" alt="" className="w-3/5 lg:w-1/3" />
        </div>
        <div className="w-4/5 lg:w-1/3 grid grid-cols-6 gap-4 items-center p-4">
          <img src="payments/mpesa.png" alt="paypal" className="w-full" />
          <img src="payments/paypal.png" alt="mpesa" className="w-full" />
          <img src="payments/visa.png" alt="visa" className="w-full" />
          <img src="payments/applepay.png" alt="mpesa" className="w-full" />
          <img src="payments/mastercard.png" alt="visa" className="w-full" />
          <img src="payments/discover.png" alt="visa" className="w-full" />
        </div>
        {/* <div>
          <img src="payments/visa.png" alt="" />
        </div> */}
      </div>
      <Carousel />
      {/* <div className="carousel h-[40dvh] lg:h-[80dvh]">
        <div className="carousel-item w-full">
          <img
            src="images/carousel_2.jpg"
            className="w-full"
            alt="Tailwind CSS Carousel component"
          />
        </div>
        <div className="carousel-item w-full">
          <img
            src="images/star_image3.JPG"
            className="-z-10 object-top -mb-8 object-cover w-full"
            alt="Tailwind CSS Carousel component"
          />
        </div>
        <div className="carousel-item w-full">
          <img
            src="images/carousel_3.jpg"
            className="w-full"
            alt="Tailwind CSS Carousel component"
          />
        </div>
        <div className="carousel-item w-full">
          <img
            src="images/carousel_4.jpg"
            className="w-full"
            alt="Tailwind CSS Carousel component"
          />
        </div>
        <div className="carousel-item w-full">
          <img
            src="images/star_image1.jpg"
            className="w-full"
            alt="Tailwind CSS Carousel component"
          />
        </div>
      </div> */}
    </div>
  );
}
