import React from 'react';

const About = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
          <img
            alt="feature"
            className="object-cover object-center h-full w-full"
            src="images/churchmap.jpg"
          />
        </div>
        <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow">
              <h2 className="sm:text-3xl text-2xl title-font font-bold text-gray-900 mt-4 mb-4">
                WHO WE ARE?
              </h2>
              <p className="leading-relaxed text-base">
                Founded in 1960, we are a non-profit organization that has been
                at the forefront of community development and social change. We
                are committed to making a difference and building a brighter
                future for all. Our mission is to help people help themselves
                and build a better future for all. We believe that everyone has
                the right to a better life and we are committed to making a
                difference and building a brighter future for all.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow">
              <h2 className="sm:text-3xl text-2xl title-font font-bold text-gray-900 mt-4 mb-4">
                WAGUSE INITIATIVE
              </h2>
              <p className="leading-relaxed text-base">
                WAGUSE is an initiative dedicated to helping those in need. we
                are committed to making a difference in the world by providing
                support and resources to those who need it most. our mission is
                to empower vulnerable communities to improve their lives through
                social and economic development. we offer holistic support
                programs for children and families, as well as community
                development initiatives. we believe that everyone deserves the
                opportunity to live a healthy, happy life, and we are dedicated
                to making that a reality for all.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow">
              <h2 className="sm:text-3xl text-2xl title-font font-bold text-gray-900 mt-4 mb-4">
                CHURCH MISSION
              </h2>
              <p className="leading-relaxed text-base">
                Our church is dedicated to serving the community and making a
                difference in the world. We believe in the power of faith and
                love to transform lives and build a better future for all. Our
                mission is to provide support and resources to those in need and
                create positive change in the world. We offer a range of
                programs and services to help people help themselves and build a
                brighter future for all.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
