import React, { useState } from 'react';
import Cards from '../sections/Cards';
import Hero from '../sections/Hero';
import Outreach from '../sections/Outreach';
import Support from '../sections/Support';
import Footer from '../sections/Footer';
import Insta from '../Assets/Insta.svg';
import Facebook from '../Assets/facebook.svg';
import LinkedIn from '../Assets/linkedin.svg';
import TikTok from '../Assets/tiktok.svg';
import Twitter from '../Assets/twitter.svg';
import Youtube from '../Assets/youtube.svg';

import 'animate.css';

const Home = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ position: 'relative' }}>
      <Hero />
      <Cards />

      <Outreach />
      <Support />
      <Footer />
      <div
        className="z-50"
        style={{ position: 'fixed', right: 50, bottom: 100, marginTop: 5 }}
      >
        {isOpen && (
          <div>
            <a
              href="https://shorturl.at/tktec"
              className="animate__animated animate__slideInUp animate__delay-4s"
              style={{
                borderRadius: 100,
                //backgroundColor: '#1877F2',
                padding: 5,
                animation: 'fadeIn 0.5s 3s forwards',
                opacity: 0,
              }}
            >
              <img src={Facebook} alt="" />
              {/* <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="h-8 w-8 text-white"
                viewBox="0 0 36 36"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg> */}
            </a>

            <a
              href="https://shorturl.at/rzBqb"
              className="animate__animated animate__slideInUp animate__delay-3s"
              style={{
                borderRadius: 100,
                //backgroundColor: 'black',
                padding: 5,
                marginTop: 5,
                animation: 'fadeIn 0.5s 2s forwards',
                opacity: 0,
              }}
            >
              <img src={Insta} alt="" />
            </a>
            <a
              href="#"
              className="animate__animated animate__slideInUp animate__delay-2s"
              style={{
                borderRadius: 100,
                //backgroundColor: 'black',
                padding: 5,
                marginTop: 5,
                animation: 'fadeIn 0.5s 2s forwards',
                opacity: 0,
              }}
            >
              <img src={LinkedIn} alt="" />
            </a>
            <a
              href="https://shorturl.at/75luy"
              className="animate__animated animate__slideInUp animate__delay-1s "
              style={{
                borderRadius: 100,
                //backgroundColor: '#bc2a8d,',
                padding: 5,
                marginTop: 5,
                animation: 'fadeIn 0.5s 1s forwards',
                opacity: 0,
              }}
            >
              <img src={TikTok} alt="" />
            </a>
            <a
              href="#"
              className="animate__animated animate__slideInUp animate__fast"
              style={{
                borderRadius: 100,
                //backgroundColor: '#0077B5 ',
                padding: 5,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <img src={Twitter} alt="" />
            </a>
            <a
              href="https://shorturl.at/Qkp4s"
              className="animate__animated animate__slideInUp animate__faster  rounded-sm"
            >
              <img src={Youtube} alt="" />
            </a>
          </div>
        )}

        <div
          onClick={toggleMenu}
          className="rounded-full items-center p-3"
          style={{
            //borderRadius: 100,
            backgroundColor: 'white',
            // padding: 5,
            cursor: 'pointer',
          }}
        >
          {isOpen ? (
            // Cross icon when open
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-8 w-8 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            // Plus icon when closed
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
