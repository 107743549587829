import React from 'react';

const Timeline = () => {
  return (
    <section className="bg-gray-400/25 body-font">
      <div className="container px-5 py-12 mx-auto ">
        <p className="inline-block py-1 px-2 rounded  text-gray-500 text-lg font-medium tracking-widest text-center">
          Our History in Caring for the Needy
        </p>
        <div className="flex relative sm:items-center md:w-2/3 mx-auto">
          <div className="px-3">1962</div>
          <div className="relative flex items-center ">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-amber-500 pointer-events-none"></div>
            </div>

            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-amber-500 text-white relative z-10 title-font font-medium text-sm"></div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row my-4">
              <div className="flex-shrink-0 w-48 h-24 bg-gray-600 rounded-md   inline-flex items-center justify-center"></div>
            </div>
          </div>
        </div>
        <div className="flex relative sm:items-center md:w-2/3 mx-auto">
          <div className="px-3">1973</div>
          <div className="relative flex items-center ">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-amber-500 pointer-events-none"></div>
            </div>

            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-amber-500 text-white relative z-10 title-font font-medium text-sm"></div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row my-4">
              <div className="flex-shrink-0 w-48 h-24 bg-gray-600 rounded-md   inline-flex items-center justify-center"></div>
            </div>
          </div>
        </div>
        <div className="flex relative sm:items-center md:w-2/3 mx-auto">
          <div className="px-3">1985</div>
          <div className="relative flex items-center ">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-amber-500 pointer-events-none"></div>
            </div>

            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-amber-500 text-white relative z-10 title-font font-medium text-sm"></div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row my-4">
              <div className="flex-shrink-0 w-48 h-24 bg-gray-600 rounded-md   inline-flex items-center justify-center"></div>
            </div>
          </div>
        </div>
        <div className="flex relative sm:items-center md:w-2/3 mx-auto">
          <div className="px-3">2002</div>
          <div className="relative flex items-center ">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-amber-500 pointer-events-none"></div>
            </div>

            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-amber-500 text-white relative z-10 title-font font-medium text-sm"></div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row my-4">
              <div className="flex-shrink-0 w-48 h-24 bg-gray-600 rounded-md   inline-flex items-center justify-center"></div>
            </div>
          </div>
        </div>
        <div className="flex relative sm:items-center md:w-2/3 mx-auto">
          <div className="px-3">2010</div>
          <div className="relative flex items-center ">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-amber-500 pointer-events-none"></div>
            </div>

            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-amber-500 text-white relative z-10 title-font font-medium text-sm"></div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row my-4">
              <div className="flex-shrink-0 w-48 h-24 bg-gray-600 rounded-md   inline-flex items-center justify-center"></div>
            </div>
          </div>
        </div>
        <div className="flex relative sm:items-center md:w-2/3 mx-auto">
          <div className="px-3">2024</div>
          <div className="relative flex items-center ">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-amber-500 pointer-events-none"></div>
            </div>

            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-amber-500 text-white relative z-10 title-font font-medium text-sm"></div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row my-4">
              <div className="flex-shrink-0 w-48 h-24 bg-gray-600 rounded-md   inline-flex items-center justify-center"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
