import React from 'react';

const SectionHero = ({ title }) => {
  return (
    <div className="h-[30dvh] lg:h-[60dvh] flex items-center justify-center relative overflow-hidden">
      <img src="images/lagos.jpg" className="object-cover" alt="" />
      <div className="md:w-full  h-full bg-black/50 justify-start p-2 md:pl-12">
        <h1 className="text-3xl lg:text-7xl uppercase absolute bottom-0 right-10 font-sans font-bold text-gray-100">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default SectionHero;
