import React from 'react';

const outreach = [
  {
    title: 'URAFIKI CHILD AND YOUTH PROGRAM',
    description:
      'Supports children and youth in their personal growth and development. It provides a safe space for children and youth to learn, play, and grow. It also offers educational support, mentorship, and life skills training.',
    url: 'images/children.jpg',
  },
  {
    title: 'SURVIVAL PROGRAM',
    description:
      'Provides food, shelter, and medical care to children and families in need. It also offers support services to help families become self-sufficient and break the cycle of poverty. it includes holistic support programs for children and families, as well as community development initiatives.',
    url: 'images/masks.jpg',
  },
  {
    title: 'EDUCATION PROGRAM',
    description:
      'Supports children and youth in their educational journey. It provides scholarships, tutoring, and educational resources to help children succeed in school and beyond.',
    url: 'images/hindi.jpg',
  },
  {
    title: 'HEALTH PROGRAM',
    description:
      'Provides medical care and health education to children and families in need. It also offers preventive care, nutrition support, and mental health services to improve the health and well-being of the community.',
    url: 'images/lagos.jpg',
  },
  {
    title: 'EMERGENCY RESPONSE PROGRAM',
    description:
      'Provides immediate assistance to children and families affected by emergencies and disasters. It offers food, shelter, medical care, and other essential services to help families recover and rebuild their lives.',
    url: 'images/oldman.jpg',
  },
];

const CausesCards = () => {
  return (
    <div className="container px-5  md:py-24 mx-auto">
      <div className="flex flex-wrap -m-4">
        {outreach.map((program) => (
          <div className="p-4 md:w-1/3 ">
            <div className="h-full relative border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
              <img
                className="lg:h-64 md:h-36 w-full object-cover object-center"
                src={program.url}
                alt="blog"
              />
              <div className="p-6 ">
                {/* <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                    CATEGORY
                  </h2> */}
                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                  {program.title}
                </h1>
                <p className="leading-relaxed mb-3">{program.description}</p>
                <div className="p-4" />
                <div className="absolute flex items-center py-4 bottom-0 flex-wrap ">
                  <a
                    className="text-gray-50 px-4 py-2 font-medium bg-green-500 rounded-full inline-flex items-center md:mb-2 lg:mb-0"
                    href="/"
                  >
                    VIEW DETAILS
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="p-4 md:w-1/3 space-y-4 ">
          <div className="flex space-x-4 items-center">
            <h1 className="text-green-700 text-7xl font-bold">906</h1>
            <p className="text-green-700 text-xl md:text-2xl font-bold mx-4">
              Alumni of our Urafiki Youth Patnership
            </p>
          </div>
          <div className="flex space-x-4 items-center">
            <h1 className="text-green-700 text-7xl font-bold">275</h1>
            <p className="text-green-700 text-xl md:text-2xl font-bold mx-4">
              Alumni of our Urafiki Youth Patnership
            </p>
          </div>
          <div className="flex space-x-4 items-center">
            <h1 className="text-green-700 text-7xl font-bold">102</h1>
            <p className="text-green-700 text-xl md:text-2xl font-bold mx-4">
              Alumni of our Urafiki Youth Patnership
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CausesCards;
