import React from 'react';

const Contact = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex justify-evenly -m-4">
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 px-20 py-6 rounded-lg relative">
              <div className="w-24 absolute top-5 -left-12 h-24 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  alt="blog"
                  src="https://yt3.googleusercontent.com/AW_Zx99_YwZOmQC6Gn1eaozFSAR32sZOHjGQ1pYYOO50eUAkZNyBx9lO1fdoGcU_uaZi2SaB=s900-c-k-c0x00ffffff-no-rj"
                  className="w-24 h-24 rounded-full flex-shrink-0 object-cover object-center"
                />
              </div>
              <h2 className="text-xl  text-gray-600 font-medium title-font mb-2">
                Your generosity changes lives
              </h2>
              <p className="leading-relaxed text-base">
                Helping others is the best way to live a fulfilling life. Your
                support helps us to reach more people and make a difference in
                the world.
              </p>
            </div>
          </div>

          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 px-20 py-6 rounded-lg relative">
              <div className="w-24 absolute top-5 -left-12 h-24 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  alt="blog"
                  src="https://yt3.googleusercontent.com/AW_Zx99_YwZOmQC6Gn1eaozFSAR32sZOHjGQ1pYYOO50eUAkZNyBx9lO1fdoGcU_uaZi2SaB=s900-c-k-c0x00ffffff-no-rj"
                  className="w-24 h-24 rounded-full flex-shrink-0 object-cover object-center"
                />
              </div>
              <h2 className="text-xl text-gray-600 font-medium title-font mb-2">
                Be the change you want to see
              </h2>
              <p className="leading-relaxed text-base">
                Your support allows us to provide essential services to those in
                need and create positive change in the world. Together, we can
                build a brighter future for all.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-12 py-12 mx-auto flex flex-wrap items-center">
        <div className="lg:w-2/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
          <span className="inline-block py-1 px-2 rounded  text-gray-500 text-xs font-medium tracking-widest">
            CONTACT US
          </span>
          <h2 className="sm:text-3xl text-2xl title-font font-bold text-gray-900  mb-4">
            GET IN TOUCH
          </h2>
          <p className="leading-relaxed mt-4 font-bold">
            For inquiries, please fill out the form below and we will get back
            to you as soon as possible.
          </p>
          <p className="font-bold mt-4 text-red-800">[T] +254 020 123 456</p>
          <p className="font-bold  text-red-800">[E] info@lucwaguse.com</p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
          <div className="relative mb-4">
            <label
              htmlFor="full-name"
              className="leading-7 text-sm text-gray-600"
            >
              Full Name
            </label>
            <input
              type="text"
              id="full-name"
              name="full-name"
              className="w-full bg-white rounded border border-gray-300 focus:border-red-800      text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full bg-white rounded border border-gray-300 focus:border-red-800     text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Message
            </label>
            <textarea
              id="email"
              name="email"
              className="w-full bg-white rounded border border-gray-300 focus:border-red-800    text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <button className="text-white bg-red-800 border-0 py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg">
            SEND
          </button>
        </div>
      </div>

      <div className="relative w-full h-[40dvh]">
        <div className="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            title="map"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=UhuruT%20Park&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
