import React from 'react';

const Outreach = () => {
  const outreach = [
    {
      title: 'SOCIAL OUTREACH',
      description:
        'Empowers vulnerable communities to improve their lives through social and economic development. it includes holistic support programs for children and families, as well as community development initiatives.',
      url: 'images/social.jpg',
      more: '/social-outreach',
    },
    {
      title: 'MISSION TO OTHERS',
      description:
        'Share the teaching of Jesus Christ and to extend the healing ministry of Jesus to all people. we commit to being a transforming, healing presence within our communities.',
      url: 'images/missionOthers.jpg',
      more: '/mission-to-others',
    },
    {
      title: 'RUN FOR A CAUSE',
      description:
        'Raise awareness and funds for a cause. we are committed to helping those in need and making a difference in the world.',
      url: '/images/runcause.jpg',
      more: '/run-for-a-cause',
    },
  ];
  return (
    <section>
      <section className="overflow-hidden pt-48 pb-12 lg:pt-[200px] lg:pb-[90px] bg-white ">
        <div className="container mx-auto">
          <div className="flex flex-wrap w items-center justify-between -mx-4">
            <div className="mb:w-2/5 px-4 ">
              <div className="flex relative -mx-3 sm:-mx-4">
                <div className="w-full px-3 sm:px-4 ">
                  <div className="py-3 px-4 md:px-2 sm:py-4">
                    <img
                      src="images/outreach.jpg"
                      alt=""
                      className="w-full h-[400px] md:h-[600px] rounded-2xl"
                    />
                  </div>
                </div>
                {/* <div className="w-full px-3 sm:px-4 ">
                  <div className="py-3 px-4 md:px-2 sm:py-4">
                    <img
                      src="images/hindi.jpg"
                      alt=""
                      className="w-full h-[400px] md:h-[600px] rounded-2xl"
                    />
                  </div>
                </div> */}
                {/* <div className=" absolute w-full bg-white p-2 rounded-2xl -bottom-20 -right-40 ">
                  <div className="relative z-10 ">
                    <img
                      src="images/students.jpg"
                      alt=""
                      className="w-full h-[200px] md:h-[300px] rounded-2xl"
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <div className="w-full px-4 lg:w-2/3 xl:w-5/12">
              <div className="py-24 px-8 md:p-12 flex flex-col items-start">
                <span className="inline-block py-1 px-2 rounded  text-gray-500 text-xs font-medium tracking-widest">
                  SOCIAL OUTREACH
                </span>
                <h2 className="sm:text-3xl text-2xl title-font font-bold text-gray-900 mt-4 mb-4">
                  MAKING A DIFFERENCE
                </h2>
                <p className="leading-relaxed mb-8">
                  WAGUSE is an initiative dedicated to helping those in need. we
                  are committed to making a difference in the world by providing
                  support and resources to those who need it most. our mission
                  is to empower vulnerable communities to improve their lives
                  through social and economic development. we offer holistic
                  support programs for children and families, as well as
                  community development initiatives. we believe that everyone
                  deserves the opportunity to live a healthy, happy life, and we
                  are dedicated to making that a reality for all.
                </p>
                <p>
                  To sustain our programs and continue making a difference, we
                  rely on the generosity of our donors and volunteers. your
                  support allows us to provide essential services to those in
                  need and create positive change in the world. together, we can
                  make a difference and build a brighter future for all.
                </p>

                <a className="inline-flex mt-4 items-center" href="/">
                  <img
                    alt="blog"
                    src="https://yt3.googleusercontent.com/AW_Zx99_YwZOmQC6Gn1eaozFSAR32sZOHjGQ1pYYOO50eUAkZNyBx9lO1fdoGcU_uaZi2SaB=s900-c-k-c0x00ffffff-no-rj"
                    className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                  />
                  <span className="flex-grow flex flex-col pl-4">
                    <span className="title-font font-medium text-gray-900">
                      Patricia Kibua
                    </span>
                    <span className="text-gray-400 text-xs tracking-widest mt-0.5">
                      SOCIAL OUTREACH STEWARD
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Outreach cards */}
      <div className="container px-5 md:py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          {outreach.map((program) => (
            <div className="p-4 md:w-1/3 ">
              <div className="h-full relative border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  className="lg:h-64 md:h-36 w-full object-cover object-center"
                  src={program.url}
                  alt="blog"
                />
                <div className="p-6 ">
                  {/* <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                    CATEGORY
                  </h2> */}
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    {program.title}
                  </h1>
                  <p className="leading-relaxed mb-3">{program.description}</p>
                  <div className="p-4" />
                  <div className="absolute flex items-center py-4 bottom-0 flex-wrap ">
                    <a
                      className="text-gray-50 px-4 py-2 font-medium bg-green-500 rounded-full inline-flex items-center md:mb-2 lg:mb-0"
                      href="/"
                    >
                      VIEW DETAILS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Outreach;
