import React from 'react';
import SectionHero from '../sections/SectionHero';
import CausesCards from '../sections/CausesCards';
import Footer from '../sections/Footer';
import Partners from '../sections/Partners';

const Causes = () => {
  return (
    <div>
      <SectionHero title={'Social Outreach'} />
      <CausesCards />
      <Partners />
      <Footer />
    </div>
  );
};

export default Causes;
