import React from 'react';

const Cards = () => {
  return (
    <section className="text-gray-600 absolute w-full -mt-6 py-4 body-font overflow-hidden">
      <div className="mx-auto">
        <div className="flex">
          <div className="xl:w-1/3 md:w-1/2 bg-green-600 w-full">
            <div className="h-full p-2 md:p-6 rounded-lg text-center relative overflow-hidden">
              <h2 className="text-xs md:text-sm text-gray-100 lg:tracking-widest  mb-1 ">
                THANK YOU FOR THE{' '}
                <span className="underline title-font font-medium">
                  RESULTS
                </span>
              </h2>
              <h2 className="text-xs md:text-sm text-gray-100 tracking-widest  mb-1 ">
                ACHIEVED WITH YOU
              </h2>
              <div className="flex justify-evenly">
                <div>
                  <h1 className="text-xl md:text-5xl text-gray-100 font-bold leading-none">
                    2354
                  </h1>
                  <h4 className="text-gray-300 text-xs md:text-sm">
                    PEOPLE HELPED
                  </h4>
                </div>
                <div>
                  <h1 className="text-xl md:text-5xl text-gray-100 font-bold leading-none">
                    1500
                  </h1>
                  <h4 className="text-gray-300 text-xs md:text-sm">GIVERS</h4>
                </div>
              </div>
            </div>
          </div>
          <div className=" xl:w-1/3 md:w-1/2 -my-4 bg-amber-500 w-full">
            <div className="h-full p-2 md:p-6 rounded-lg  flex flex-col relative overflow-hidden">
              <h2 className="text-xl md:text-2xl text-center text-gray-200 tracking-wide title-font mb-1 font-bold">
                GOALS ACHIEVED
              </h2>
              {/* <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200"></h1>
              <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200"></h1>
              <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200"></h1> */}
            </div>
          </div>
          <div className=" xl:w-1/3 md:w-1/2 bg-red-800 w-full">
            <div className="h-full p-2 md:p-6 text-center rounded-lg items-center relative overflow-hidden">
              <h4 className="text-gray-300">HELP US REACH</h4>
              <h2 className="text-2xl tracking-wide text-gray-200 title-font mb-4 font-bold">
                NEXT GOAL
              </h2>
              <div className="flex justify-center">
                <h4 className="p-2 text-sm tracking-widest border hover:text-red-800  hover:bg-gray-200 text-gray-100 rounded-full border-gray-200">
                  GIVE NOW
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cards;
