import React, { useState } from 'react';
import { sendStkPush } from '../actions/stkPush';
import { stkPushQuery } from '../actions/stkPushQuery';

function PaymentForm() {
  const [loading, setLoading] = useState(false);
  const [dataFromForm, setDataFromForm] = useState({
    mpesa_phone: '',
    name: '',
    amount: 0,
  });
  const [stkQueryLoading, setStkQueryLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //add this just before the handleSubmit Function
  var reqcount = 0;

  const stkPushQueryWithIntervals = (CheckoutRequestID) => {
    const timer = setInterval(async () => {
      reqcount += 1;

      if (reqcount === 15) {
        //handle long payment
        clearInterval(timer);
        setStkQueryLoading(false);
        setLoading(false);
        setErrorMessage('You took too long to pay');
      }

      const { data, error } = await stkPushQuery(CheckoutRequestID);

      if (error) {
        if (error.response.data.errorCode !== '500.001.1001') {
          setStkQueryLoading(false);
          setLoading(false);
          setErrorMessage(error?.response?.data?.errorMessage);
        }
      }

      if (data) {
        if (data.ResultCode === '0') {
          clearInterval(timer);
          setStkQueryLoading(false);
          setLoading(false);
          setSuccess(true);
        } else {
          clearInterval(timer);
          setStkQueryLoading(false);
          setLoading(false);
          setErrorMessage(data?.ResultDesc);
        }
      }
    }, 2000);
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(dataFromForm);
    const formData = {
      mpesa_number: dataFromForm.mpesa_phone.trim(),
      name: dataFromForm.name.trim(),
      amount: dataFromForm.amount,
    };

    const kenyanPhoneNumberRegex =
      /^(07\d{8}|01\d{8}|2547\d{8}|2541\d{8}|\+2547\d{8}|\+2541\d{8})$/;

    if (!kenyanPhoneNumberRegex.test(formData.mpesa_number)) {
      setLoading(false);
      return alert('Invalid mpesa number');
    }

    const { data: stkData, error: stkError } = await sendStkPush(formData);

    if (stkError) {
      setLoading(false);
      return alert(stkError);
    }

    const checkoutRequestId = stkData.CheckoutRequestID;

    setStkQueryLoading(true);
    stkPushQueryWithIntervals(checkoutRequestId);
  };

  return (
    <div className="lg:pl-12">
      <div className="overflow-hidden rounded-md bg-white">
        <div className="p-6 sm:p-10">
          <img src="payments/mpesapay.png" alt="" className="w-1/2 mx-auto" />
          <p className="mt-4 text-base text-gray-600">
            Provide your name, mpesa number and amount to process donation.
          </p>
          <form className="mt-4">
            <div className="space-y-6">
              <div>
                <label className="text-base font-medium text-gray-900">
                  {' '}
                  Name{' '}
                </label>
                <div className="relative mt-2.5">
                  <input
                    type="text"
                    required
                    name="name"
                    value={dataFromForm.name}
                    onChange={(e) =>
                      setDataFromForm({
                        ...dataFromForm,
                        name: e.target.value,
                      })
                    }
                    placeholder="John Doe"
                    className="block w-full rounded-md border border-gray-200 bg-white px-4 py-4 text-black placeholder-gray-500 caret-red-500 transition-all duration-200 focus:border-red-500 focus:outline-none focus:ring-red-500"
                  />
                </div>
              </div>
              <div>
                <label className="text-base font-medium text-gray-900">
                  {' '}
                  Mpesa Number{' '}
                </label>
                <div className="relative mt-2.5">
                  <input
                    type="text"
                    name="mpesa_number"
                    value={dataFromForm.mpesa_phone}
                    onChange={(e) =>
                      setDataFromForm({
                        ...dataFromForm,
                        mpesa_phone: e.target.value,
                      })
                    }
                    placeholder="Enter mpesa phone number"
                    className="block w-full rounded-md border border-gray-200 bg-white px-4 py-4 text-black placeholder-gray-500 caret-red-500 transition-all duration-200 focus:border-red-500 focus:outline-none focus:ring-red-500"
                  />
                </div>
              </div>
              <div>
                <label className="text-base font-medium text-gray-900">
                  {' '}
                  Amount{' '}
                </label>
                <div className="relative mt-2.5">
                  <input
                    type="number"
                    required
                    name="amount"
                    value={dataFromForm.amount}
                    onChange={(e) =>
                      setDataFromForm({
                        ...dataFromForm,
                        amount: Number(e.target.value),
                      })
                    }
                    placeholder="Enter an active whatsapp number"
                    className="block w-full rounded-md border border-gray-200 bg-white px-4 py-4 text-black placeholder-gray-500 caret-red-500 transition-all duration-200 focus:border-red-500 focus:outline-none focus:ring-red-500"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  disabled={loading}
                  className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-500 px-4 py-4 text-base font-semibold text-white transition-all duration-200 hover:bg-green-600 focus:bg-green-600 focus:outline-none"
                >
                  {loading ? 'Processing..' : 'Proceed With Payment'}
                </button>
              </div>
              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      'https://www.paypal.com/donate/?hosted_button_id=94E8HEQ74EN4U';
                  }}
                  disabled={loading}
                  className="inline-flex w-full items-center justify-center rounded-md border border-transparent   text-base font-semibold text-white transition-all duration-200 hover:bg-green-200 hover:border-green-600 focus:bg-green-300 focus:outline-none"
                >
                  <div className=" flex items-center justify-center gap-8 py-4">
                    <img
                      className="h-8 w-auto "
                      src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg"
                      alt=""
                    />

                    <img
                      className="h-8 w-auto "
                      src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg"
                      alt=""
                    />

                    <img
                      className="h-8 w-auto"
                      src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg"
                      alt=""
                    />
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
