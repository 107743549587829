import React from 'react';

const Partners = () => {
  return (
    <div className="bg-gray-500">
      <div className="container mx-auto p-4">
        <h2 className="text-2xl text-gray-300 font-bold text-center">
          Our Partners
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 place-items-center">
          <img src="partners/compassion.png" alt="" />
          <img src="partners/kenya.png" alt="" />
          <img src="partners/compassion.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
