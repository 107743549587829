import React from 'react';
import Contact from '../sections/Contact';
import Footer from '../sections/Footer';
import SectionHero from '../sections/SectionHero';

const Contacts = () => {
  return (
    <div>
      <SectionHero title={'Contacts'} />
      <Contact />
      <Footer />
    </div>
  );
};

export default Contacts;
