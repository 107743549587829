import React from 'react';
import SectionHero from '../sections/SectionHero';
import Timeline from '../sections/Timeline';
import About from '../sections/About';
import Footer from '../sections/Footer';

const AboutUs = () => {
  return (
    <div>
      <SectionHero title={'About Us'} />
      <About />
      <Timeline />
      <Footer />
    </div>
  );
};

export default AboutUs;
