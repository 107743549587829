import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AboutUs from './About';
import Causes from './Causes';
import Contacts from './Contacts';
import Home from './Home';
import Navbar from '../sections/Navbar';
import { Dialog, DialogBackdrop } from '@headlessui/react';
import PaymentForm from '../sections/Payment';
import { XMarkIcon } from '@heroicons/react/24/outline';

const RoutedData = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Navbar modal={setOpen} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/causes" element={<Causes />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            {/* <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            > */}
            <section className="relative z-50 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full md:max-w-7xl  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95  p-8 antialiased  md:py-16">
              <XMarkIcon
                onClick={() => setOpen(false)}
                className="bg-red-700 h-8 w-8 absolute right-0 top-0 text-white"
              />
              <PaymentForm />
              {/* <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
                <div className="mx-auto max-w-5xl">
                  <h2 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                    Payment
                  </h2>

                  <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12">
                    <form
                      action="#"
                      className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow-sm   sm:p-6 lg:max-w-xl lg:p-8"
                    >
                      <div className="mb-6 grid grid-cols-2 gap-4">
                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="full_name"
                            className="mb-2 block text-sm font-medium text-gray-900 "
                          >
                            Full name (as displayed on card)*{' '}
                          </label>
                          <input
                            type="text"
                            id="full_name"
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500      "
                            placeholder="Bonnie Green"
                            required
                          />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="card-number-input"
                            className="mb-2 block text-sm font-medium text-gray-900 "
                          >
                            {' '}
                            Card number*{' '}
                          </label>
                          <input
                            type="text"
                            id="card-number-input"
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500       "
                            placeholder="xxxx-xxxx-xxxx-xxxx"
                            pattern="^4[0-9]{12}(?:[0-9]{3})?$"
                            required
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="card-expiration-input"
                            className="mb-2 block text-sm font-medium text-gray-900 "
                          >
                            Card expiration*{' '}
                          </label>
                          <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
                              <svg
                                className="h-4 w-4 text-gray-500 "
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <input
                              datepicker-format="mm/yy"
                              id="card-expiration-input"
                              type="text"
                              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 ps-9 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 "
                              placeholder="12/23"
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="cvv-input"
                            className="mb-2 flex items-center gap-1 text-sm font-medium text-gray-900 "
                          >
                            CVV*
                            <button
                              data-tooltip-target="cvv-desc"
                              data-tooltip-trigger="hover"
                              className="text-gray-400 hover:text-gray-900  "
                            >
                              <svg
                                className="h-4 w-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </button>
                            <div
                              id="cvv-desc"
                              role="tooltip"
                              className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 "
                            >
                              The last 3 digits on back of card
                              <div
                                className="tooltip-arrow"
                                data-popper-arrow
                              ></div>
                            </div>
                          </label>
                          <input
                            type="number"
                            id="cvv-input"
                            aria-describedby="helper-text-explanation"
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500      "
                            placeholder="•••"
                            required
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="flex w-full items-center justify-center rounded-lg bg-red-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4  focus:ring-primary-300 "
                      >
                        Pay now
                      </button>
                    </form>

                    <div className="mt-6 grow sm:mt-8 lg:mt-0">
                      <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6  ">
                        <div className="space-y-2">
                          <dl className="flex items-center justify-between gap-4">
                            <dt className="text-base font-normal text-gray-500 ">
                              Original price
                            </dt>
                            <dd className="text-base font-medium text-gray-900 ">
                              $6,592.00
                            </dd>
                          </dl>

                          <dl className="flex items-center justify-between gap-4">
                            <dt className="text-base font-normal text-gray-500 ">
                              Savings
                            </dt>
                            <dd className="text-base font-medium text-green-500">
                              -$299.00
                            </dd>
                          </dl>

                          <dl className="flex items-center justify-between gap-4">
                            <dt className="text-base font-normal text-gray-500 ">
                              Store Pickup
                            </dt>
                            <dd className="text-base font-medium text-gray-900 ">
                              $99
                            </dd>
                          </dl>

                          <dl className="flex items-center justify-between gap-4">
                            <dt className="text-base font-normal text-gray-500 ">
                              Tax
                            </dt>
                            <dd className="text-base font-medium text-gray-900 ">
                              $799
                            </dd>
                          </dl>
                        </div>

                        <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                          <dt className="text-base font-bold text-gray-900 ">
                            Total
                          </dt>
                          <dd className="text-base font-bold text-gray-900 ">
                            $7,191.00
                          </dd>
                        </dl>
                      </div>

                      <div className="mt-6 flex items-center justify-center gap-8">
                        <img
                          className="h-8 w-auto "
                          src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg"
                          alt=""
                        />
                        <img
                          className="hidden h-8 w-auto "
                          src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal-dark.svg"
                          alt=""
                        />
                        <img
                          className="h-8 w-auto "
                          src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg"
                          alt=""
                        />
                        <img
                          className="hidden h-8 w-auto "
                          src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa-dark.svg"
                          alt=""
                        />
                        <img
                          className="h-8 w-auto"
                          src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg"
                          alt=""
                        />
                        <img
                          className="hidden h-8 w-auto "
                          src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard-dark.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <p className="mt-6 text-center text-gray-500  sm:mt-8 lg:text-left">
                    Payment processed by{' '}
                    <a
                      href="#"
                      title=""
                      className="font-medium text-primary-700 underline hover:no-underline "
                    >
                      Paddle
                    </a>{' '}
                    for{' '}
                    <a
                      href="#"
                      title=""
                      className="font-medium text-primary-700 underline hover:no-underline "
                    >
                      Flowbite LLC
                    </a>
                    - United States Of America
                  </p>
                </div>
              </div> */}
            </section>
            {/* <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-red-600"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Deactivate account
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to deactivate your account? All of
                        your data will be permanently removed. This action
                        cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Deactivate
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div> */}
            {/* </DialogPanel> */}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RoutedData;
